import React from 'react';
import { MDBBtn } from 'mdbreact';
import AvailableToApproveWorkOrder from './AvailableToApproveWorkOrder';
import NotEnoughFunds from './NotEnoughFunds';
import WorkOrderHaveExpenses from './WorkOrderHaveExpenses';

const Content = ({
  contentType,
  workOrdersCount,
  fundsInfo,
  closeModal,
  submitApprove,
  addFunds,
  workOrderCountWithExpenses,
  submitApproveAll,
  submitApproveWorkOrderWithoutExpenses,
}) => {
  switch (contentType) {
    case 'approveAvailable':
      return (
        <AvailableToApproveWorkOrder
          workOrdersCount={workOrdersCount}
          fundsInfo={fundsInfo}
          closeModal={closeModal}
          submitApprove={submitApprove}
        />
      );
    case 'notEnoughFunds':
      return (
        <NotEnoughFunds
          fundsInfo={fundsInfo}
          closeModal={closeModal}
          addFunds={addFunds}
        />
      );
    case 'workOrderHaveExpenses':
      return (
        <WorkOrderHaveExpenses
          fundsInfo={fundsInfo}
          workOrderCountWithExpenses={workOrderCountWithExpenses}
          closeModal={closeModal}
          submitApproveAll={submitApproveAll}
          submitApproveWorkOrderWithoutExpenses={submitApproveWorkOrderWithoutExpenses}
        />
      );
    default:
      return (
        <div className="flex-box flex-center full-width">
          <h6>Something goes wrong!</h6>
          <label>Try again later.</label>
          <MDBBtn
            onClick={closeModal}
            label="Close"
          />
        </div>
      );
  }
};

export default Content;
