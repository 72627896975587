import React from "react";
import {MDBBtn, MDBCol, MDBRow} from "mdbreact";
import {localCurrencySettings} from "../../../../utils/constans";

const WorkOrderHaveExpenses = (
    {
        closeModal,
        submitApproveAll,
        submitApproveWorkOrderWithoutExpenses,
        workOrderCountWithExpenses,
        fundsInfo
    }
) => (
    <>
        <MDBRow>
            <MDBCol>
                <h5 style={{textAlign: 'center'}} >{workOrderCountWithExpenses} Work Orders Contain Expenses!</h5>
            </MDBCol>
        </MDBRow>
        <MDBRow>
            <MDBCol>
                Expenses: {fundsInfo.expenses.toLocaleString('en-US',localCurrencySettings)}
            </MDBCol>
        </MDBRow>
        <MDBRow className='flex-center' >
            <MDBCol className='flex-grow-0' >
                <MDBBtn onClick={closeModal} >Close</MDBBtn>
            </MDBCol>
            <MDBCol className='flex-grow-0' >
                <MDBBtn color='success' onClick={submitApproveWorkOrderWithoutExpenses} >Skip</MDBBtn>
            </MDBCol>
            <MDBCol className='flex-grow-0' >
                <MDBBtn color='success' onClick={submitApproveAll} >Continue</MDBBtn>
            </MDBCol>
        </MDBRow>
    </>
)

export default WorkOrderHaveExpenses;